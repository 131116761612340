import { faCoffee, faCloudSun, faClock, faHandshake ,faCloudSunRain,faArrowCircleUp, faArrowCircleDown, faCircleChevronUp, faRoute, faStar} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum SORT_KEYS {
  PRICE_HIGH_TO_LOW = "PRICE_HIGH_TO_LOW",
  PRICE_LOW_TO_HIGH = "PRICE_LOW_TO_HIGH",
  RATING_HIGH_TO_LOW = "RATING_HIGH_TO_LOW",
  RATING_LOW_TO_HIGH = "RATING_LOW_TO_HIGH",
  DISTANCE_NEAREST = "DISTANCE_NEAREST_FIRST",
}

export const SORT_LABELS = {
  PRICE_HIGH_TO_LOW: "PRICE HIGH TO LOW",
  PRICE_LOW_TO_HIGH: "PRICE LOW TO HIGH",
  RATING_HIGH_TO_LOW: "RATING HIGH TO LOW",
  RATING_LOW_TO_HIGH: "RATING LOW TO HIGH",
  DISTANCE_NEAREST: "DISTANCE NEAREST FIRST",
};

export const sortOptions = [
  {
    key: SORT_KEYS.PRICE_LOW_TO_HIGH,
    label: SORT_LABELS.PRICE_LOW_TO_HIGH,
  },
  {
    key: SORT_KEYS.PRICE_HIGH_TO_LOW,
    label: SORT_LABELS.PRICE_HIGH_TO_LOW,
  },
  {
    key: SORT_KEYS.RATING_HIGH_TO_LOW,
    label: SORT_LABELS.RATING_HIGH_TO_LOW,
  },
  {
    key: SORT_KEYS.RATING_LOW_TO_HIGH,
    label: SORT_LABELS.RATING_LOW_TO_HIGH,
  },
  {
    key: SORT_KEYS.DISTANCE_NEAREST,
    label: SORT_LABELS.DISTANCE_NEAREST,
  },
  
];
export const SORT_ICONS: Record<SORT_KEYS, IconDefinition> = {
  [SORT_KEYS.PRICE_LOW_TO_HIGH]: faArrowCircleUp,
  [SORT_KEYS.PRICE_HIGH_TO_LOW]: faArrowCircleDown,
  [SORT_KEYS.RATING_HIGH_TO_LOW]: faStar,
  [SORT_KEYS.RATING_LOW_TO_HIGH]: faCircleChevronUp,
  [SORT_KEYS.DISTANCE_NEAREST]: faRoute,
};
export enum FILTERS_KEYS {
  CAFE = "CAFE",
  COVERED = "COVERED",
  OPEN_24_7 = "OPEN_24_7",
  PARTNERED = "PARTNERED",
  UNCOVERED = "UNCOVERED"
}

export const FILTERS_LABELS = {
  CAFE: "CAFE",
  COVERED: "COVERED",
  OPEN_24_7: "OPEN 24*7",
  PARTNERED: "PARTNERED",
  UNCOVERED: "UNCOVERED"
};

export const FILTERS_ICONS: Record<FILTERS_KEYS, IconDefinition> = {
  [FILTERS_KEYS.CAFE]: faCoffee,
  [FILTERS_KEYS.COVERED]: faCloudSun,
  [FILTERS_KEYS.OPEN_24_7]: faClock,
  [FILTERS_KEYS.PARTNERED]: faHandshake,
  [FILTERS_KEYS.UNCOVERED]: faCloudSunRain,
};

export enum SIZE_RANGE {
  MIN = 2000,
  MAX = 20000,
}

export const DISTANCE_RANGE_MAX = 30