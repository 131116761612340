import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
  Typography,
  message
} from "antd";
import "./index.scss";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Toast from "../../../components/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../../utils/constants";
import DurationCounter from "../../../components/DurationCounter";
import { formatDuration } from "../../../utils/formatDuration";

type Props = {};
const to = `+91${constants.BUISNESS_NUMBER}`;

const Booking = ({
  name,
  city,
  turfNumber,
}: {
  name: string | null;
  city: string | undefined;
  turfNumber: string | undefined;
}) => {
  const navigate = useNavigate();
  const [duration, setDuration] = useState(60); // Initial duration in minutes
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoader, setIsLoader] = useState(false);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const smsUrl = process.env.REACT_APP_TWILIO_API_BASE_URL;
  const scriptUrl = process.env.REACT_APP_GOOGLE_APPS_SCRIPT_URL;
    const [previousTime, setPreviousTime] = useState(new Date()); // Last valid time

  const { Text, Title } = Typography;
  const queryParams = new URLSearchParams(window.location.search);
  const area = queryParams.get("area");
  const price = queryParams.get('price'); // Retrieve price

  const turfName = name || "";

  const [form] = Form.useForm();

  useEffect(() => {
    const initialStartDate = new Date();
    initialStartDate.setMinutes(
      Math.ceil(initialStartDate.getMinutes() / 15) * 15
    );
    setStartDate(initialStartDate);
    setTime(initialStartDate);
  }, []);

  useEffect(() => {
    startDate.setHours(time.getHours());
    startDate.setMinutes(time.getMinutes());
    startDate.setSeconds(time.getSeconds());
    startDate.setMilliseconds(0); // Set milliseconds to 0 as specified

    // Format the combined result to the desired ISO string format

    setEndDate(new Date(startDate?.getTime() + duration * 60000));
  }, [startDate, duration, time]);

  const handleTimeChange = (time: Date) => {
    setTime(time);
  };

  const onFinish = async (values: any) => {
    try {
      const currentDateTime = new Date();

      if (startDate <= currentDateTime) {
        message.error("The selected date and time must be later than the current time.");
        return;
      }
      const startTime = moment(startDate).format("llll");
      const onlyDate = moment(startDate).format("DD/MM/YYYY"); // Adjust the format as needed
      const onlyTime = moment(startDate).format("hh:mm A"); // Format for time with AM/PM

      const endTime = moment(endDate).format("llll");
      const onlyEndTime = moment(endDate).format("hh:mm A"); // Format for time with AM/PM

      const bodyToTurf = `Hello from TurfBooking.in,%0a%0aWe have received a request to book your turf ${encodeURIComponent(
        values.turfName
      )} for *${formatDuration(
        duration
      )}* %0a%0aTiming - *${startTime}* to *${endTime}*.%0a%0aIs the turf available?%0a%0aIf not, could you please let us know the nearest available slot?%0a%0aAlso, please provide the price for booking .`;
      const bookingConfirmMsg = `Greetings from turfbooking.in%0a%0aCongratulations your booking has been confirmed for *${encodeURIComponent(
        values.turfName
      )}*%0a%0aDate - ${onlyDate}%0a%0aTiming - *${onlyTime}* to *${onlyEndTime}*.%0a%0aFor any queries please contact ${constants.BUISNESS_NUMBER
        }.%0a%0aWebsite - www.turfbooking.in`;
      let bodyToAdmin = `
                          TurfName:  ${values.turfName} 
                          City: ${city}
                          ${area ? `Area: ${area} sq.ft` : ""}
                          Mobile Number: <tel:${values.mobile}|${values.mobile
        }> (<https://wa.me/+91${values.mobile
        }?text=Hello from TurfBooking.in,%0a%0aWe have received your request to book the *${encodeURIComponent(
          values.turfName
        )}* for *${formatDuration(
          duration
        )}* %0a%0aTiming - *${startTime}* to *${endTime}*.%0a%0aPlease confirm the booking time and date, and we will be happy to assist you.|WhatsApp>)
                          (<https://wa.me/+91${turfNumber}?text=${bodyToTurf}|turfWhatsapp>)
                          (<https://wa.me/+91${values.mobile
        }?text=${bookingConfirmMsg}|bookingConfirmToCustomer>)
                          (<https://wa.me/+91${turfNumber}?text=${bookingConfirmMsg}|bookingConfirmToTurf>)
                          Name: ${values.customerName}
                          ${values.email ? `Email: ${values.email}` : ""}  
                          From:  ${startTime}
                          To:  ${endTime}
                          Duration: ${formatDuration(duration)}
                          `;

      const bodyToCustomer = `Thank you for booking ${values.turfName} with us! Our team will reach out soon to finalize the details. For queries, contact us at ${to}.`;
      let channel;
      //Don't send notification for test emails
      if (values.email?.toLowerCase()?.includes("test")) {
        channel = "test";
        bodyToAdmin =
          `<@U062RDQH423> <@U06332C1N21> <@U06TX6E45GQ> <@U06U673FC02> <@U062ZAK1S3G>` +
          bodyToAdmin;
        // setIsLoader(false);
        // setIsToast(true);
        // setSuccess(true);
        // setMsg("Thanks for Booking");

        // // Redirect to success page after 2 seconds
        // setTimeout(() => {
        //   navigate("/success");
        // }, 2000);
        // return;
      } else {
        channel = "production";
        bodyToAdmin =
          `<@U062RDQH423> <@U06332C1N21> <@U0632DWNKMZ> <@U062ZAK1S3G>` +
          bodyToAdmin;
      }

      setIsLoader(true);

      // Send both SMS simultaneously
      // const adminResponse = await fetch(`${smsUrl}/sendsms`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ body: bodyToAdmin, to: to }), // Send SMS to recipient's mobile number
      // });

      const slackNotification = await fetch(`${smsUrl}/sendSlackNotification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: bodyToAdmin, channel }),
      });

      // const customerResponse = await fetch(`${smsUrl}/sendsms`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     body: bodyToCustomer,
      //     to: `+91${values.mobile}`,
      //   }), // Send SMS to customer's mobile number
      // });

      // const adminData = await adminResponse.json();
      // const customerData = await customerResponse.json();
      const notification = await slackNotification.json();

      if (notification.success) {
        const googleSheetsResponse = await fetch(`${scriptUrl}`, {
          // const googleSheetsResponse = await fetch("https://script.google.com/macros/s/AKfycbxRTiG0r7nbY13vDFaqLTRn2dx_7hNifH2qavYWArVMgacHBpTaI36GQ2dmu5Ouop29/exec", {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...values,
            area: area,
          }),
        });

        setIsLoader(false);
        setIsToast(true);
        setSuccess(true);
        setMsg("Thanks for Booking");

        // Redirect to success page after 2 seconds
        setTimeout(() => {
          navigate("/success");
        }, 2000);
      }
    } catch (err) {
      setIsLoader(false);
      setIsToast(true);
      setSuccess(false);
      console.log(err);
      setMsg("Failed to Book");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const goBack = () => {
    navigate(`/?area=${area}&price=${price}`);
  };
  const handleClose = () => {
    setSuccess(false);
    setIsToast(false);
    setMsg("");
  };
  const startDateFormat = moment(startDate).format("YYYY-MM-DD");
  const todaysDate = moment(new Date()).format("YYYY-MM-DD");
  return (
    <div className="main-container">
      <Button
        type="primary"
        className="back-button"
        onClick={goBack}
        shape="circle"

        icon={<ArrowLeftOutlined />}
      />
      <div className="booking-container">
        <span className="title">
          <Title>Raise Enquiry</Title>
        </span>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            layout: "vertical",
            turfName: turfName,
            startDate,
            endDate,
            time,
            duration,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ maxWidth: 600 }}
          className="booking-form"
        >
          <Form.Item label={<Title level={5}>Turf Name</Title>} name="turfName">
            <Input
              size="large"
              placeholder="Enter Turf Name"
              disabled
              defaultValue={turfName}
            />
          </Form.Item>
          {area && (
            <Form.Item label={<Title level={5}>Area</Title>} name="Area">
              <Input
                size="large"
                placeholder="Pitch Area"
                disabled
                defaultValue={area}
              />
            </Form.Item>
          )}
          <Form.Item
            label={<Title level={5}>Mobile No.</Title>}
            name="mobile"
            rules={[
              { required: true, message: "Please enter your mobile number!" },
              {
                pattern: /^[6-9][0-9]{9}$/,
                message: "Please enter a valid 10-digit mobile number!",
              },
            ]}
          >
            <InputNumber
              type="text"
              size="large"
              min={0}
              controls={false}
              style={{ width: "100%" }}
              placeholder="Enter Mobile Number"
              autoComplete="tel"
            />
          </Form.Item>
          <Form.Item
            label={<Title level={5}>Name</Title>}
            name="customerName"
            rules={[{ required: true, message: "Please enter your name!" }]}
          >
            <Input size="large" placeholder="Enter your Name" />
          </Form.Item>

          <Form.Item
            label={<Title level={5}>Email</Title>}
            name="email"
            rules={[
              // { required: true, message: "Please enter your email address!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input size="large" placeholder="Enter Email address" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <Title ellipsis level={5}>
                    Date
                  </Title>
                }
                name="startDate"
                rules={[{ required: true, message: "Please select a date!" }]}
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setStartDate(date);
                    }
                  }}
                  minDate={new Date()}
                  onBlur={() => {
                    if (!startDate) {
                      setStartDate(new Date());
                    }
                  }}
                />
              </Form.Item>
            </Col>

            {/* <Row gutter={16}> */}
            <Col span={12}>
              <Form.Item
                label={
                  <Title ellipsis level={5}>
                    Start Time
                  </Title>
                }
                name="time"
                rules={[{ required: true, message: "Please select a time!" }]}
              >
                <DatePicker
                  selected={time}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setTime(date);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"

                  onKeyDown={(e: any) => {
                    if (e.key !== "Tab" && e.key !== "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onFocus={(e: any) => e.target.blur()}
                  filterTime={(time: Date) => {
                    const areDatesEqual = moment(startDate).isSame(new Date(), 'day');
                    if (areDatesEqual) {
                      const currentDate = new Date();
                      const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
                      const selectedTime = time.getHours() * 60 + time.getMinutes();
                      return selectedTime >= currentTime;
                    }
                    return true;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}> */}
          <Form.Item
            label={
              <Title ellipsis level={5}>
                Duration
              </Title>
            }
            name="duration"
          >
            <DurationCounter duration={duration} setDuration={setDuration} />
          </Form.Item>
          {/* </Row> */}
          <Form.Item noStyle>
            <Button
              type="primary"
              size="large"
              block={true}
              disabled={isLoader}
              htmlType="submit"
            >
              {isLoader ? <Spin size="small" /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
        {isToast && (
          <Toast
            message={msg}
            success={success}
            onClose={handleClose}
            placement="bottom"
          />
        )}
      </div>
    </div>
  );
};

export default Booking;
